import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Input,
  PopoverBody,
  UncontrolledAlert,
  UncontrolledPopover,
} from "reactstrap";
import { InputGroup, Form } from "react-bootstrap";

import { IModalProps } from "../../../components/CDataModal";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";

function copyToClipboard(
  toggleModal: () => void,
  setModal: Function,
  tokenString: string,
  isCreate: boolean,
) {
  navigator.clipboard.writeText(tokenString);

  let title = "Personal Access Token Regenerated Successfully!";
  if (isCreate) {
    title = "Personal Access Token Created Successfully!";
  }
  const modal = {
    title: title,
    body: (
      <>
        <UncontrolledAlert color="tertiary" className="alert-box">
          <div className="alert-message">
            <strong>Wait!</strong> Make sure to copy your personal access token
            now, you won&apos;t be able to see it again.
          </div>
        </UncontrolledAlert>
        Your personal access token is:
        <InputGroup>
          <Input
            disabled
            type="text"
            name="text"
            id="token"
            value={tokenString}
          />
          <Button
            id="copyToClipboardButton"
            name="copyToClipboardButton"
            color="secondary"
            onClick={() =>
              copyToClipboard(toggleModal, setModal, tokenString, isCreate)
            }
          >
            <FontAwesomeIcon
              icon={faCopy}
              className="align-middle no-pointer-event"
            />
          </Button>
          <UncontrolledPopover
            placement="top"
            target="copyToClipboardButton"
            trigger="legacy"
          >
            <PopoverBody>Copied Successfully!</PopoverBody>
          </UncontrolledPopover>
        </InputGroup>
      </>
    ),
    primaryButton: <></>,
    secondaryButton: (
      <Button color="primary" onClick={toggleModal}>
        OK
      </Button>
    ),
    modalSize: "lg",
    displayToggleCloseButton: false,
    displayed: true,
  } as IModalProps;

  setModal(modal);
}

function openPersonalAccessTokenModal(
  setModal: Function,
  createPersonalAccessToken: Function,
  toggleModal: () => void,
) {
  const modal = {
    title: "Create New Personal Access Token",
    body: (
      <Form
        id="personalAccessTokenForm"
        onSubmit={(event: React.FormEvent) => createPersonalAccessToken(event)}
      >
        <Form.Group>
          <h5>Name:</h5>
          <Form.Control
            name="name"
            placeholder="Enter a name for your PAT"
            type="text"
            disabled={getIsSupportImpersonationActive()}
            defaultValue={
              getIsSupportImpersonationActive() ? "CData Support" : ""
            }
          />
        </Form.Group>
      </Form>
    ),
    primaryButton: (
      <Button color="primary" type="submit" form="personalAccessTokenForm">
        Create
      </Button>
    ),
    secondaryButton: (
      <Button color="secondary" onClick={toggleModal}>
        Cancel
      </Button>
    ),
    modalSize: "lg",
    displayed: true,
  } as IModalProps;
  setModal(modal);
}

function openRegeneratePersonalAccessTokenModal(
  setModal: Function,
  personalAccessTokenId: string,
  toggleModal: () => void,
  setToken: Function,
  regeneratePersonalAccessToken: () => void,
) {
  const modal = {
    title: "Regenerate Personal Access Token",
    body: (
      <text>
        Are you sure you want to regenerate this personal access token?
      </text>
    ),
    primaryButton: (
      <Button
        color="primary"
        type="button"
        onClick={regeneratePersonalAccessToken}
        data-testid="button-modal-regenerate-pat"
      >
        Regenerate
      </Button>
    ),
    secondaryButton: (
      <Button
        color="secondary"
        onClick={toggleModal}
        data-testid="button-modal-cancel"
      >
        Cancel
      </Button>
    ),
    modalSize: "lg",
    displayed: true,
  } as IModalProps;
  setToken(personalAccessTokenId);
  setModal(modal);
}

function successMessage(
  tokenString: string,
  toggleModal: () => void,
  setToken: Function,
  setModal: Function,
  isCreate: boolean,
) {
  let title = "Personal Access Token Regenerated Successfully!";
  if (isCreate) {
    title = "Personal Access Token Created Successfully!";
  }

  const modal = {
    title: title,
    body: (
      <>
        <UncontrolledAlert color="tertiary" className="alert-box">
          <div className="alert-message">
            <strong>Wait!</strong> Make sure to copy your personal access token
            now, you won&apos;t be able to see it again.
          </div>
        </UncontrolledAlert>
        Your personal access token is:
        <InputGroup>
          <Input
            disabled
            type="text"
            name="text"
            id="token"
            value={tokenString}
          />
          <Button
            id="copyToClipboardButton"
            name="copyToClipboardButton"
            color="secondary"
            onClick={() =>
              copyToClipboard(toggleModal, setModal, tokenString, isCreate)
            }
          >
            <FontAwesomeIcon
              icon={faCopy}
              className="align-middle no-pointer-event"
            />
          </Button>
          <UncontrolledPopover
            placement="top"
            target="copyToClipboardButton"
            trigger="legacy"
          >
            <PopoverBody>Copied Successfully!</PopoverBody>
          </UncontrolledPopover>
        </InputGroup>
      </>
    ),
    primaryButton: <></>,
    secondaryButton: (
      <Button color="primary" onClick={toggleModal} disabled>
        OK
      </Button>
    ),
    modalSize: "lg",
    displayToggleCloseButton: false,
    displayed: true,
  } as IModalProps;

  setToken(tokenString);
  setModal(modal);
}

function openDeletePersonalAccessTokenModal(
  setModal: Function,
  deletePersonalAccessToken: () => void,
  toggleModal: () => void,
  setToken: Function,
  personalAccessTokenId: string,
) {
  const modal = {
    title: "Delete Personal Access Token",
    body: (
      <text>Are you sure you want to delete this personal access token?</text>
    ),
    primaryButton: (
      <Button color="danger" onClick={deletePersonalAccessToken}>
        Confirm
      </Button>
    ),
    secondaryButton: (
      <Button color="secondary" onClick={toggleModal}>
        Cancel
      </Button>
    ),
    modalSize: "md",
    displayed: true,
  } as IModalProps;

  setToken(personalAccessTokenId);
  setModal(modal);
}

export {
  openPersonalAccessTokenModal,
  openRegeneratePersonalAccessTokenModal,
  successMessage,
  openDeletePersonalAccessTokenModal,
};
