import React from "react";
import { Link } from "react-router-dom";
import { Button, Navbar } from "reactstrap";
import CDataIcon from "../../assets/img/icons/CloudLogo.svg?react";

interface Page404Props {
  showBranding: boolean;
}

const Page404 = (props: Page404Props) => (
  <>
    {props.showBranding ? (
      <Navbar color="white" light expand>
        <Button
          transparent="true"
          color="white"
          className="no-pointer-event ps-0"
        >
          <CDataIcon className="cdata-logo" aria-description="CData logo" />
        </Button>
      </Navbar>
    ) : null}
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">Page not found.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        The page you are looking for might have been removed.
      </p>
      <Link to="/">
        <Button color="primary" size="lg">
          Return to website
        </Button>
      </Link>
    </div>
  </>
);

export default Page404;
