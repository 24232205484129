import * as React from "react";
import { useIsConnectForSpreadsheets } from "../hooks/useIsConnectForSpreadsheets";

/**
 * This blocker component will block the children component from rendering, if only feature is OnlySpreadsheetClients.
 */
const ConnectForSpreadsheetsBlocker = (props: {
  children: React.ReactNode;
}) => {
  const { children } = props;

  const isOnlySpreadsheetClient = useIsConnectForSpreadsheets();

  return isOnlySpreadsheetClient ? <></> : children;
};

export default ConnectForSpreadsheetsBlocker;
