//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum UserRole {
  Admin = 0,
  Query = 1,
  OEMAdmin = 2,
  ServiceUser = 3
}
