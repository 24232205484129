//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BillingStatus {
  NewAccount = 0,
  Trial = 1,
  TrialEnding = 2,
  Active = 3,
  Unpaid = 4,
  Canceled = 5,
  ActiveEnding = 6,
  Paused = 7
}
