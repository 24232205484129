import { Formik } from "formik";
import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { ModalContext } from "../routes/ModalContext";
import { ToastrError, ToastrSuccess } from "../services/toastrService";
import TextInput from "../components/form/TextInput";
import { Button } from "reactstrap";
import { IModalProps } from "../components/CDataModal";
import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import * as Yup from "yup";

export function useLicenseModals(setSubscriptionInfo: Function) {
  const modalFunctions = useContext(ModalContext);
  const api = useAPI();
  async function addNewLicense(values: { ProductKey: string }) {
    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/account/license/${values.ProductKey}`,
      "Failed to install license due to the following error:",
    );
    if (status === 200) {
      setSubscriptionInfo(payload);
      ToastrSuccess(
        "License successfully installed",
        `The ${payload?.plan?.name} product key was successfully installed.`,
      );
    } else {
      ToastrError("License installation failed", "Invalid product key.");
    }
    modalFunctions.toggleModal();
  }

  const validationSchema = Yup.object().shape({
    ProductKey: Yup.string().required("A Product Key is required."),
  });

  function openAddLicenseModal() {
    const initialFormValues = {
      ProductKey: "",
    };
    const modalTitle = "Install New License";

    const modalBody = (
      <>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={addNewLicense}
        >
          {({ handleSubmit }) => (
            <Form id="newLicenseForm" onSubmit={handleSubmit}>
              <TextInput
                label={"Product Key"}
                name={"ProductKey"}
                isRequired={true}
              />
            </Form>
          )}
        </Formik>
      </>
    );

    const modal = {
      title: modalTitle,
      body: modalBody,
      primaryButton: (
        <Button color="primary" form="newLicenseForm" type="submit">
          <div className="icon no-pointer-event" />
          Confirm
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function deleteLicense(productKey: string) {
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/account/license/${productKey}`,
      "Failed to remove license key:",
    );
    if (status === 200) {
      ToastrSuccess(
        "License successfully deleted",
        "The Security Add-On product key was successfully deleted.",
      );
    }
    modalFunctions.toggleModal();
  }

  function openDeleteLicenseModal(licenseKey: string) {
    const modalTitle = "Delete Product Key";

    const modalBody = (
      <>
        <text>
          You are about to delete the {licenseKey} product key which could
          affect performance of the application. Are you sure you want to
          proceed?
        </text>
      </>
    );

    const modal = {
      title: modalTitle,
      body: modalBody,
      primaryButton: (
        <Button color="danger" onClick={() => deleteLicense(licenseKey)}>
          <div className="icon no-pointer-event" />
          Delete
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  const modalAndAPIs = {
    addLicenseConfirmation: openAddLicenseModal,
    deleteLicenseConfirmation: openDeleteLicenseModal,
  };

  return modalAndAPIs;
}
