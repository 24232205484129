import { isFeatureAllowed } from "../utility/SubscriptionAddonsFactory";
import { FeatureId } from "../models";
import { useAppSelector } from "../redux/hooks";

export function useIsConnectForSpreadsheets() {
  return isFeatureAllowed(
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [],
    FeatureId.ConnectForSpreadsheets,
  );
}
