import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AccessTokenTab from "./AccessTokenTab/AccessTokenTab";
import { AccountTab } from "./AccountTab/AccountTab";
import UserProfileTab from "./UserProfileTab/UserProfileTab";
import StripeBillingInfoTab from "./BillingInfoTab/StripeBillingInfoTab";
import LicenseInfoTab from "./LicenseInfoTab/LicenseInfoTab";
import { UserRole } from "../../models";
import TabWrapper from "../../components/TabWrapper";
import {
  isBillingTabVisible,
  isLicenseTabVisible,
} from "../../utility/SubscriptionProvider";
import { ModalContext } from "../../routes/ModalContext";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { useAppSelector } from "../../redux/hooks";

export enum SettingTabs {
  UserProfile = "UserProfile",
  Account = "Account",
  AccountSettings = "AccountSettings",
  Billing = "Billing",
  License = "License",
}

const Settings: React.FC = () => {
  const { setModal, toggleModal, showError, showTimeout } =
    useContext(ModalContext);

  const navigate = useNavigate();

  const user = useAppSelector((u) => u.user);

  const isSpreadsheetUser = useIsConnectForSpreadsheets();
  const isAdmin = user.role === UserRole.Admin;
  const isOem =
    user.role === UserRole.OEMAdmin || user.role === UserRole.ServiceUser;

  const showBackButton = window.history.length > 1;

  return (
    <Container fluid className={`p-0 pages-settings-Settings`}>
      <div>
        <Row className="mb-4">
          <Col className="ms-1 settings-header-container">
            {showBackButton && (
              <ButtonBase
                onClick={() => navigate(-1)}
                className="back-button"
                aria-label="Go Back"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="lg"
                  className="back-arrow"
                />
              </ButtonBase>
            )}

            <h1 className="h3 settings-header">Settings</h1>
          </Col>
        </Row>
        <TabWrapper
          tabs={[
            {
              tabName: "Profile",
              tabEnum: SettingTabs.UserProfile,
              tabComponent: <UserProfileTab />,
              isVisible: true,
            },
            {
              tabName: "Account",
              tabEnum: SettingTabs.Account,
              tabComponent: <AccountTab />,
              isVisible: isAdmin || isOem,
            },
            {
              tabName: "Access Tokens",
              tabEnum: SettingTabs.AccountSettings,
              tabComponent: (
                <AccessTokenTab
                  setModal={setModal}
                  toggleModal={toggleModal}
                  showErrorModal={showError}
                  showTimeoutModal={showTimeout}
                />
              ),
              isVisible: !isOem && !isSpreadsheetUser,
            },
            {
              tabName: "Billing",
              tabEnum: SettingTabs.Billing,
              tabComponent: <StripeBillingInfoTab />,
              isVisible: (isAdmin || isOem) && isBillingTabVisible(),
            },
            {
              tabName: "License",
              tabEnum: SettingTabs.License,
              tabComponent: (
                <LicenseInfoTab
                  setModal={setModal}
                  toggleModal={toggleModal}
                  showErrorModal={showError}
                  showTimeoutModal={showTimeout}
                />
              ),
              isVisible: isAdmin && isLicenseTabVisible(),
            },
          ]}
        />
      </div>
    </Container>
  );
};

export default Settings;
