import * as types from "../constants";

interface IFlyoutState {
  isOpen?: boolean;
  docUrl?: string;
  name?: string;
  source?: string;
  section?: string;
}

const initialState: IFlyoutState = {
  isOpen: false,
  docUrl: "",
  name: "",
  source: "",
  section: "",
};

export default function reducer(
  state: IFlyoutState = initialState,
  actions: any,
): IFlyoutState {
  switch (actions.type) {
    case types.FLYOUT_VISIBILITY_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
        docUrl: actions.docUrl,
        name: actions.name,
        source: actions.source,
        section: actions.section,
      };

    case types.FLYOUT_VISIBILITY_SHOW:
      return {
        ...state,
        isOpen: true,
        docUrl: actions.docUrl,
        name: actions.name,
        source: actions.source,
        section: actions.section,
      };

    case types.FLYOUT_VISIBILITY_HIDE:
      return {
        ...state,
        isOpen: false,
        docUrl: undefined,
        name: undefined,
        source: undefined,
        section: undefined,
      };

    default:
      return state;
  }
}
