import React from "react";
import { Button } from "reactstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TertiaryButton from "../../../components/buttons/TertiaryButton";
import { IUser } from "../../../models";

interface IVerificationButtons {
  userInfo: IUser;
}

const VerificationButtons = (props: IVerificationButtons) => {
  let pendingVerificationButton,
    resendVerificationButton,
    cancelVerificationButton;

  if (props.userInfo?.pendingEmail) {
    pendingVerificationButton = (
      <Button
        color="warning"
        className="pending-verification-button ms-2 card-actions no-pointer-event"
        data-testid="button-pending-verification"
      >
        Pending Verification
      </Button>
    );
    resendVerificationButton = (
      <Button
        color="secondary"
        className="card-actions"
        data-testid="button-resend-verification"
      >
        <FontAwesomeIcon
          icon={faPaperPlane}
          className="small-icon align-middle no-pointer-event"
        />
        Resend Verification
      </Button>
    );
    cancelVerificationButton = (
      <TertiaryButton
        key="selectConnectionButton"
        className="cancel-verification-button"
        data-testid="button-cancel-verification"
      >
        Cancel Verification Request
      </TertiaryButton>
    );
  }

  // Return an object containing all three buttons
  return {
    pendingVerificationButton,
    resendVerificationButton,
    cancelVerificationButton,
  };
};

export default VerificationButtons;
