import { ICountry } from "../models/Geography/ICountry";

export const countryList: ICountry[] = [
  { name: "Afghanistan", threeLetterCode: "AFG", twoLetterCode: "AF" },
  { name: "Åland Islands", threeLetterCode: "ALA", twoLetterCode: "AX" },
  { name: "Albania", threeLetterCode: "ALB", twoLetterCode: "AL" },
  { name: "Algeria", threeLetterCode: "DZA", twoLetterCode: "DZ" },
  { name: "American Samoa", threeLetterCode: "ASM", twoLetterCode: "AS" },
  { name: "Andorra", threeLetterCode: "AND", twoLetterCode: "AD" },
  { name: "Angola", threeLetterCode: "AGO", twoLetterCode: "AO" },
  { name: "Anguilla", threeLetterCode: "AIA", twoLetterCode: "AI" },
  { name: "Antarctica", threeLetterCode: "ATA", twoLetterCode: "AQ" },
  { name: "Antigua and Barbuda", threeLetterCode: "ATG", twoLetterCode: "AG" },
  { name: "Argentina", threeLetterCode: "ARG", twoLetterCode: "AR" },
  { name: "Armenia", threeLetterCode: "ARM", twoLetterCode: "AM" },
  { name: "Aruba", threeLetterCode: "ABW", twoLetterCode: "AW" },
  { name: "Australia", threeLetterCode: "AUS", twoLetterCode: "AU" },
  { name: "Austria", threeLetterCode: "AUT", twoLetterCode: "AT" },
  { name: "Azerbaijan", threeLetterCode: "AZE", twoLetterCode: "AZ" },
  { name: "Bahamas", threeLetterCode: "BHS", twoLetterCode: "BS" },
  { name: "Bahrain", threeLetterCode: "BHR", twoLetterCode: "BH" },
  { name: "Bangladesh", threeLetterCode: "BGD", twoLetterCode: "BD" },
  { name: "Barbados", threeLetterCode: "BRB", twoLetterCode: "BB" },
  { name: "Belarus", threeLetterCode: "BLR", twoLetterCode: "BY" },
  { name: "Belgium", threeLetterCode: "BEL", twoLetterCode: "BE" },
  { name: "Belize", threeLetterCode: "BLZ", twoLetterCode: "BZ" },
  { name: "Benin", threeLetterCode: "BEN", twoLetterCode: "BJ" },
  { name: "Bermuda", threeLetterCode: "BMU", twoLetterCode: "BM" },
  { name: "Bhutan", threeLetterCode: "BTN", twoLetterCode: "BT" },
  {
    name: "Bolivia, Plurinational State of",
    threeLetterCode: "BOL",
    twoLetterCode: "BO",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    threeLetterCode: "BES",
    twoLetterCode: "BQ",
  },
  {
    name: "Bosnia and Herzegovina",
    threeLetterCode: "BIH",
    twoLetterCode: "BA",
  },
  { name: "Botswana", threeLetterCode: "BWA", twoLetterCode: "BW" },
  { name: "Bouvet Island", threeLetterCode: "BVT", twoLetterCode: "BV" },
  { name: "Brazil", threeLetterCode: "BRA", twoLetterCode: "BR" },
  {
    name: "British Indian Ocean Territory",
    threeLetterCode: "IOT",
    twoLetterCode: "IO",
  },
  { name: "Brunei Darussalam", threeLetterCode: "BRN", twoLetterCode: "BN" },
  { name: "Bulgaria", threeLetterCode: "BGR", twoLetterCode: "BG" },
  { name: "Burkina Faso", threeLetterCode: "BFA", twoLetterCode: "BF" },
  { name: "Burundi", threeLetterCode: "BDI", twoLetterCode: "BI" },
  { name: "Cabo Verde", threeLetterCode: "CPV", twoLetterCode: "CV" },
  { name: "Cambodia", threeLetterCode: "KHM", twoLetterCode: "KH" },
  { name: "Cameroon", threeLetterCode: "CMR", twoLetterCode: "CM" },
  { name: "Canada", threeLetterCode: "CAN", twoLetterCode: "CA" },
  { name: "Cayman Islands", threeLetterCode: "CYM", twoLetterCode: "KY" },
  {
    name: "Central African Republic",
    threeLetterCode: "CAF",
    twoLetterCode: "CF",
  },
  { name: "Chad", threeLetterCode: "TCD", twoLetterCode: "TD" },
  { name: "Chile", threeLetterCode: "CHL", twoLetterCode: "CL" },
  { name: "China", threeLetterCode: "CHN", twoLetterCode: "CN" },
  { name: "Christmas Island", threeLetterCode: "CXR", twoLetterCode: "CX" },
  {
    name: "Cocos (Keeling) Islands",
    threeLetterCode: "CCK",
    twoLetterCode: "CC",
  },
  { name: "Colombia", threeLetterCode: "COL", twoLetterCode: "CO" },
  { name: "Comoros", threeLetterCode: "COM", twoLetterCode: "KM" },
  { name: "Congo", threeLetterCode: "COG", twoLetterCode: "CG" },
  {
    name: "Congo, Democratic Republic of the",
    threeLetterCode: "COD",
    twoLetterCode: "CD",
  },
  { name: "Cook Islands", threeLetterCode: "COK", twoLetterCode: "CK" },
  { name: "Costa Rica", threeLetterCode: "CRI", twoLetterCode: "CR" },
  { name: "Côte d'Ivoire", threeLetterCode: "CIV", twoLetterCode: "CI" },
  { name: "Croatia", threeLetterCode: "HRV", twoLetterCode: "HR" },
  { name: "Cuba", threeLetterCode: "CUB", twoLetterCode: "CU" },
  { name: "Curaçao", threeLetterCode: "CUW", twoLetterCode: "CW" },
  { name: "Cyprus", threeLetterCode: "CYP", twoLetterCode: "CY" },
  { name: "Czechia", threeLetterCode: "CZE", twoLetterCode: "CZ" },
  { name: "Denmark", threeLetterCode: "DNK", twoLetterCode: "DK" },
  { name: "Djibouti", threeLetterCode: "DJI", twoLetterCode: "DJ" },
  { name: "Dominica", threeLetterCode: "DMA", twoLetterCode: "DM" },
  { name: "Dominican Republic", threeLetterCode: "DOM", twoLetterCode: "DO" },
  { name: "Ecuador", threeLetterCode: "ECU", twoLetterCode: "EC" },
  { name: "Egypt", threeLetterCode: "EGY", twoLetterCode: "EG" },
  { name: "El Salvador", threeLetterCode: "SLV", twoLetterCode: "SV" },
  { name: "Equatorial Guinea", threeLetterCode: "GNQ", twoLetterCode: "GQ" },
  { name: "Eritrea", threeLetterCode: "ERI", twoLetterCode: "ER" },
  { name: "Estonia", threeLetterCode: "EST", twoLetterCode: "EE" },
  { name: "Eswatini", threeLetterCode: "SWZ", twoLetterCode: "SZ" },
  { name: "Ethiopia", threeLetterCode: "ETH", twoLetterCode: "ET" },
  {
    name: "Falkland Islands (Malvinas)",
    threeLetterCode: "FLK",
    twoLetterCode: "FK",
  },
  { name: "Faroe Islands", threeLetterCode: "FRO", twoLetterCode: "FO" },
  { name: "Fiji", threeLetterCode: "FJI", twoLetterCode: "FJ" },
  { name: "Finland", threeLetterCode: "FIN", twoLetterCode: "FI" },
  { name: "France", threeLetterCode: "FRA", twoLetterCode: "FR" },
  { name: "French Guiana", threeLetterCode: "GUF", twoLetterCode: "GF" },
  { name: "French Polynesia", threeLetterCode: "PYF", twoLetterCode: "PF" },
  {
    name: "French Southern Territories",
    threeLetterCode: "ATF",
    twoLetterCode: "TF",
  },
  { name: "Gabon", threeLetterCode: "GAB", twoLetterCode: "GA" },
  { name: "Gambia", threeLetterCode: "GMB", twoLetterCode: "GM" },
  { name: "Georgia", threeLetterCode: "GEO", twoLetterCode: "GE" },
  { name: "Germany", threeLetterCode: "DEU", twoLetterCode: "DE" },
  { name: "Ghana", threeLetterCode: "GHA", twoLetterCode: "GH" },
  { name: "Gibraltar", threeLetterCode: "GIB", twoLetterCode: "GI" },
  { name: "Greece", threeLetterCode: "GRC", twoLetterCode: "GR" },
  { name: "Greenland", threeLetterCode: "GRL", twoLetterCode: "GL" },
  { name: "Grenada", threeLetterCode: "GRD", twoLetterCode: "GD" },
  { name: "Guadeloupe", threeLetterCode: "GLP", twoLetterCode: "GP" },
  { name: "Guam", threeLetterCode: "GUM", twoLetterCode: "GU" },
  { name: "Guatemala", threeLetterCode: "GTM", twoLetterCode: "GT" },
  { name: "Guernsey", threeLetterCode: "GGY", twoLetterCode: "GG" },
  { name: "Guinea", threeLetterCode: "GIN", twoLetterCode: "GN" },
  { name: "Guinea-Bissau", threeLetterCode: "GNB", twoLetterCode: "GW" },
  { name: "Guyana", threeLetterCode: "GUY", twoLetterCode: "GY" },
  { name: "Haiti", threeLetterCode: "HTI", twoLetterCode: "HT" },
  {
    name: "Heard Island and McDonald Islands",
    threeLetterCode: "HMD",
    twoLetterCode: "HM",
  },
  { name: "Holy See", threeLetterCode: "VAT", twoLetterCode: "VA" },
  { name: "Honduras", threeLetterCode: "HND", twoLetterCode: "HN" },
  { name: "Hong Kong", threeLetterCode: "HKG", twoLetterCode: "HK" },
  { name: "Hungary", threeLetterCode: "HUN", twoLetterCode: "HU" },
  { name: "Iceland", threeLetterCode: "ISL", twoLetterCode: "IS" },
  { name: "India", threeLetterCode: "IND", twoLetterCode: "IN" },
  { name: "Indonesia", threeLetterCode: "IDN", twoLetterCode: "ID" },
  {
    name: "Iran, Islamic Republic of",
    threeLetterCode: "IRN",
    twoLetterCode: "IR",
  },
  { name: "Iraq", threeLetterCode: "IRQ", twoLetterCode: "IQ" },
  { name: "Ireland", threeLetterCode: "IRL", twoLetterCode: "IE" },
  { name: "Isle of Man", threeLetterCode: "IMN", twoLetterCode: "IM" },
  { name: "Israel", threeLetterCode: "ISR", twoLetterCode: "IL" },
  { name: "Italy", threeLetterCode: "ITA", twoLetterCode: "IT" },
  { name: "Jamaica", threeLetterCode: "JAM", twoLetterCode: "JM" },
  { name: "Japan", threeLetterCode: "JPN", twoLetterCode: "JP" },
  { name: "Jersey", threeLetterCode: "JEY", twoLetterCode: "JE" },
  { name: "Jordan", threeLetterCode: "JOR", twoLetterCode: "JO" },
  { name: "Kazakhstan", threeLetterCode: "KAZ", twoLetterCode: "KZ" },
  { name: "Kenya", threeLetterCode: "KEN", twoLetterCode: "KE" },
  { name: "Kiribati", threeLetterCode: "KIR", twoLetterCode: "KI" },
  {
    name: "Korea, the Democratic People's Republic of",
    threeLetterCode: "PRK",
    twoLetterCode: "KP",
  },
  {
    name: "Korea, the Republic of",
    threeLetterCode: "KOR",
    twoLetterCode: "KR",
  },
  { name: "Kuwait", threeLetterCode: "KWT", twoLetterCode: "KW" },
  { name: "Kyrgyzstan", threeLetterCode: "KGZ", twoLetterCode: "KG" },
  {
    name: "Lao People's Democratic Republic",
    threeLetterCode: "LAO",
    twoLetterCode: "LA",
  },
  { name: "Latvia", threeLetterCode: "LVA", twoLetterCode: "LV" },
  { name: "Lebanon", threeLetterCode: "LBN", twoLetterCode: "LB" },
  { name: "Lesotho", threeLetterCode: "LSO", twoLetterCode: "LS" },
  { name: "Liberia", threeLetterCode: "LBR", twoLetterCode: "LR" },
  { name: "Libya", threeLetterCode: "LBY", twoLetterCode: "LY" },
  { name: "Liechtenstein", threeLetterCode: "LIE", twoLetterCode: "LI" },
  { name: "Lithuania", threeLetterCode: "LTU", twoLetterCode: "LT" },
  { name: "Luxembourg", threeLetterCode: "LUX", twoLetterCode: "LU" },
  { name: "Macao", threeLetterCode: "MAC", twoLetterCode: "MO" },
  { name: "Madagascar", threeLetterCode: "MDG", twoLetterCode: "MG" },
  { name: "Malawi", threeLetterCode: "MWI", twoLetterCode: "MW" },
  { name: "Malaysia", threeLetterCode: "MYS", twoLetterCode: "MY" },
  { name: "Maldives", threeLetterCode: "MDV", twoLetterCode: "MV" },
  { name: "Mali", threeLetterCode: "MLI", twoLetterCode: "ML" },
  { name: "Malta", threeLetterCode: "MLT", twoLetterCode: "MT" },
  { name: "Marshall Islands", threeLetterCode: "MHL", twoLetterCode: "MH" },
  { name: "Martinique", threeLetterCode: "MTQ", twoLetterCode: "MQ" },
  { name: "Mauritania", threeLetterCode: "MRT", twoLetterCode: "MR" },
  { name: "Mauritius", threeLetterCode: "MUS", twoLetterCode: "MU" },
  { name: "Mayotte", threeLetterCode: "MYT", twoLetterCode: "YT" },
  { name: "Mexico", threeLetterCode: "MEX", twoLetterCode: "MX" },
  {
    name: "Micronesia, Federated States of",
    threeLetterCode: "FSM",
    twoLetterCode: "FM",
  },
  {
    name: "Moldova, the Republic of",
    threeLetterCode: "MDA",
    twoLetterCode: "MD",
  },
  { name: "Monaco", threeLetterCode: "MCO", twoLetterCode: "MC" },
  { name: "Mongolia", threeLetterCode: "MNG", twoLetterCode: "MN" },
  { name: "Montenegro", threeLetterCode: "MNE", twoLetterCode: "ME" },
  { name: "Montserrat", threeLetterCode: "MSR", twoLetterCode: "MS" },
  { name: "Morocco", threeLetterCode: "MAR", twoLetterCode: "MA" },
  { name: "Mozambique", threeLetterCode: "MOZ", twoLetterCode: "MZ" },
  { name: "Myanmar", threeLetterCode: "MMR", twoLetterCode: "MM" },
  { name: "Namibia", threeLetterCode: "NAM", twoLetterCode: "NA" },
  { name: "Nauru", threeLetterCode: "NRU", twoLetterCode: "NR" },
  { name: "Nepal", threeLetterCode: "NPL", twoLetterCode: "NP" },
  {
    name: "Netherlands, Kingdom of the",
    threeLetterCode: "NLD",
    twoLetterCode: "NL",
  },
  { name: "New Caledonia", threeLetterCode: "NCL", twoLetterCode: "NC" },
  { name: "New Zealand", threeLetterCode: "NZL", twoLetterCode: "NZ" },
  { name: "Nicaragua", threeLetterCode: "NIC", twoLetterCode: "NI" },
  { name: "Niger", threeLetterCode: "NER", twoLetterCode: "NE" },
  { name: "Nigeria", threeLetterCode: "NGA", twoLetterCode: "NG" },
  { name: "Niue", threeLetterCode: "NIU", twoLetterCode: "NU" },
  { name: "Norfolk Island", threeLetterCode: "NFK", twoLetterCode: "NF" },
  { name: "North Macedonia", threeLetterCode: "MKD", twoLetterCode: "MK" },
  {
    name: "Northern Mariana Islands",
    threeLetterCode: "MNP",
    twoLetterCode: "MP",
  },
  { name: "Norway", threeLetterCode: "NOR", twoLetterCode: "NO" },
  { name: "Oman", threeLetterCode: "OMN", twoLetterCode: "OM" },
  { name: "Pakistan", threeLetterCode: "PAK", twoLetterCode: "PK" },
  { name: "Palau", threeLetterCode: "PLW", twoLetterCode: "PW" },
  { name: "Palestine, State of", threeLetterCode: "PSE", twoLetterCode: "PS" },
  { name: "Panama", threeLetterCode: "PAN", twoLetterCode: "PA" },
  { name: "Papua New Guinea", threeLetterCode: "PNG", twoLetterCode: "PG" },
  { name: "Paraguay", threeLetterCode: "PRY", twoLetterCode: "PY" },
  { name: "Peru", threeLetterCode: "PER", twoLetterCode: "PE" },
  { name: "Philippines", threeLetterCode: "PHL", twoLetterCode: "PH" },
  { name: "Pitcairn", threeLetterCode: "PCN", twoLetterCode: "PN" },
  { name: "Poland", threeLetterCode: "POL", twoLetterCode: "PL" },
  { name: "Portugal", threeLetterCode: "PRT", twoLetterCode: "PT" },
  { name: "Puerto Rico", threeLetterCode: "PRI", twoLetterCode: "PR" },
  { name: "Qatar", threeLetterCode: "QAT", twoLetterCode: "QA" },
  { name: "Réunion", threeLetterCode: "REU", twoLetterCode: "RE" },
  { name: "Romania", threeLetterCode: "ROU", twoLetterCode: "RO" },
  { name: "Russian Federation", threeLetterCode: "RUS", twoLetterCode: "RU" },
  { name: "Rwanda", threeLetterCode: "RWA", twoLetterCode: "RW" },
  { name: "Saint Barthélemy", threeLetterCode: "BLM", twoLetterCode: "BL" },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    threeLetterCode: "SHN",
    twoLetterCode: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    threeLetterCode: "KNA",
    twoLetterCode: "KN",
  },
  { name: "Saint Lucia", threeLetterCode: "LCA", twoLetterCode: "LC" },
  {
    name: "Saint Martin (French part)",
    threeLetterCode: "MAF",
    twoLetterCode: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    threeLetterCode: "SPM",
    twoLetterCode: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    threeLetterCode: "VCT",
    twoLetterCode: "VC",
  },
  { name: "Samoa", threeLetterCode: "WSM", twoLetterCode: "WS" },
  { name: "San Marino", threeLetterCode: "SMR", twoLetterCode: "SM" },
  {
    name: "Sao Tome and Principe",
    threeLetterCode: "STP",
    twoLetterCode: "ST",
  },
  { name: "Saudi Arabia", threeLetterCode: "SAU", twoLetterCode: "SA" },
  { name: "Senegal", threeLetterCode: "SEN", twoLetterCode: "SN" },
  { name: "Serbia", threeLetterCode: "SRB", twoLetterCode: "RS" },
  { name: "Seychelles", threeLetterCode: "SYC", twoLetterCode: "SC" },
  { name: "Sierra Leone", threeLetterCode: "SLE", twoLetterCode: "SL" },
  { name: "Singapore", threeLetterCode: "SGP", twoLetterCode: "SG" },
  {
    name: "Sint Maarten (Dutch part)",
    threeLetterCode: "SXM",
    twoLetterCode: "SX",
  },
  { name: "Slovakia", threeLetterCode: "SVK", twoLetterCode: "SK" },
  { name: "Slovenia", threeLetterCode: "SVN", twoLetterCode: "SI" },
  { name: "Solomon Islands", threeLetterCode: "SLB", twoLetterCode: "SB" },
  { name: "Somalia", threeLetterCode: "SOM", twoLetterCode: "SO" },
  { name: "South Africa", threeLetterCode: "ZAF", twoLetterCode: "ZA" },
  {
    name: "South Georgia and the South Sandwich Islands",
    threeLetterCode: "SGS",
    twoLetterCode: "GS",
  },
  { name: "South Sudan", threeLetterCode: "SSD", twoLetterCode: "SS" },
  { name: "Spain", threeLetterCode: "ESP", twoLetterCode: "ES" },
  { name: "Sri Lanka", threeLetterCode: "LKA", twoLetterCode: "LK" },
  { name: "Sudan", threeLetterCode: "SDN", twoLetterCode: "SD" },
  { name: "Suriname", threeLetterCode: "SUR", twoLetterCode: "SR" },
  {
    name: "Svalbard and Jan Mayen",
    threeLetterCode: "SJM",
    twoLetterCode: "SJ",
  },
  { name: "Sweden", threeLetterCode: "SWE", twoLetterCode: "SE" },
  { name: "Switzerland", threeLetterCode: "CHE", twoLetterCode: "CH" },
  { name: "Syrian Arab Republic", threeLetterCode: "SYR", twoLetterCode: "SY" },
  {
    name: "Taiwan (Province of China)",
    threeLetterCode: "TWN",
    twoLetterCode: "TW",
  },
  { name: "Tajikistan", threeLetterCode: "TJK", twoLetterCode: "TJ" },
  {
    name: "Tanzania, the United Republic of",
    threeLetterCode: "TZA",
    twoLetterCode: "TZ",
  },
  { name: "Thailand", threeLetterCode: "THA", twoLetterCode: "TH" },
  { name: "Timor-Leste", threeLetterCode: "TLS", twoLetterCode: "TL" },
  { name: "Togo", threeLetterCode: "TGO", twoLetterCode: "TG" },
  { name: "Tokelau", threeLetterCode: "TKL", twoLetterCode: "TK" },
  { name: "Tonga", threeLetterCode: "TON", twoLetterCode: "TO" },
  { name: "Trinidad and Tobago", threeLetterCode: "TTO", twoLetterCode: "TT" },
  { name: "Tunisia", threeLetterCode: "TUN", twoLetterCode: "TN" },
  { name: "Türkiye", threeLetterCode: "TUR", twoLetterCode: "TR" },
  { name: "Turkmenistan", threeLetterCode: "TKM", twoLetterCode: "TM" },
  {
    name: "Turks and Caicos Islands",
    threeLetterCode: "TCA",
    twoLetterCode: "TC",
  },
  { name: "Tuvalu", threeLetterCode: "TUV", twoLetterCode: "TV" },
  { name: "Uganda", threeLetterCode: "UGA", twoLetterCode: "UG" },
  { name: "Ukraine", threeLetterCode: "UKR", twoLetterCode: "UA" },
  { name: "United Arab Emirates", threeLetterCode: "ARE", twoLetterCode: "AE" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    threeLetterCode: "GBR",
    twoLetterCode: "GB",
  },
  {
    name: "United States Minor Outlying Islands",
    threeLetterCode: "UMI",
    twoLetterCode: "UM",
  },
  {
    name: "United States of America",
    threeLetterCode: "USA",
    twoLetterCode: "US",
  },
  { name: "Uruguay", threeLetterCode: "URY", twoLetterCode: "UY" },
  { name: "Uzbekistan", threeLetterCode: "UZB", twoLetterCode: "UZ" },
  { name: "Vanuatu", threeLetterCode: "VUT", twoLetterCode: "VU" },
  {
    name: "Venezuela, Bolivarian Republic of",
    threeLetterCode: "VEN",
    twoLetterCode: "VE",
  },
  { name: "Viet Nam", threeLetterCode: "VNM", twoLetterCode: "VN" },
  {
    name: "Virgin Islands (British)",
    threeLetterCode: "VGB",
    twoLetterCode: "VG",
  },
  {
    name: "Virgin Islands (U.S.)",
    threeLetterCode: "VIR",
    twoLetterCode: "VI",
  },
  { name: "Wallis and Futuna", threeLetterCode: "WLF", twoLetterCode: "WF" },
  { name: "Western Sahara", threeLetterCode: "ESH", twoLetterCode: "EH" },
  { name: "Yemen", threeLetterCode: "YEM", twoLetterCode: "YE" },
  { name: "Zambia", threeLetterCode: "ZMB", twoLetterCode: "ZM" },
  { name: "Zimbabwe", threeLetterCode: "ZWE", twoLetterCode: "ZW" },
];

export const getCountryNameByCode = (code: string): string | undefined => {
  return countryList.find((country) => country.threeLetterCode === code)?.name;
};
