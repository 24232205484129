import { useContext } from "react";
import { AuthorizeContext } from "../components/guards/UserAuthenticationGuard";
import { UserRole } from "../models/Users/UserRole";

export function useUserInfo() {
  const authContext = useContext(AuthorizeContext);

  function isInRole(role: UserRole) {
    return authContext?.role === role;
  }

  const modalAndAPIs = {
    IsInRole: isInRole,
    Self: authContext,
  };

  return modalAndAPIs;
}
