import { FormGroup } from "react-bootstrap";
import { BillingStatus, ISubscriptionInfo } from "../../../models";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { useIsConnectForSpreadsheets } from "../../../hooks/useIsConnectForSpreadsheets";

interface INextBillingDate {
  subscriptionInfo: ISubscriptionInfo | undefined;
}

export const NextBillingDate = ({ subscriptionInfo }: INextBillingDate) => {
  if (
    !subscriptionInfo ||
    !subscriptionInfo.billingStatus ||
    // Special case for local dev, shouldn't affect production but will keep local dev from blowing up
    !subscriptionInfo.nextBillTime ||
    subscriptionInfo?.billingStatus === BillingStatus.Canceled
  ) {
    return null;
  }

  const isConnectForSpreadsheetsClient = useIsConnectForSpreadsheets();

  const isTrialOrTrialEnding =
    subscriptionInfo.billingStatus === BillingStatus.Trial ||
    subscriptionInfo.billingStatus === BillingStatus.TrialEnding;

  const title = isTrialOrTrialEnding ? "Trial End Date" : "Next Billing Date";

  let nextBillDateOrExpirationMessage = "";
  if (isTrialOrTrialEnding) {
    const expirationDate = formatUTCDateTime(
      subscriptionInfo.nextBillTime,
      "MMMM do, yyyy",
    );

    nextBillDateOrExpirationMessage = isConnectForSpreadsheetsClient
      ? `Your trial is scheduled to expire on ${expirationDate} and will be downgraded to the Connect Spreadsheets "Free" plan.`
      : `Your plan is scheduled to be canceled on ${expirationDate}.`;
  } else if (subscriptionInfo.billingStatus === BillingStatus.Unpaid) {
    nextBillDateOrExpirationMessage =
      "Your payment is currently past due. Please click on Manage Billing and pay your outstanding balance to not risk losing access to CData Connect Cloud.";
  } else {
    nextBillDateOrExpirationMessage = formatUTCDateTime(
      subscriptionInfo.nextBillTime,
      "MMMM do, yyyy",
    );
  }

  const billingMessage = isConnectForSpreadsheetsClient
    ? "Enter your billing information before the trial ends to avoid losing all premium features of Connect Spreadsheets"
    : "Enter your billing information before the trial ends to continue using all features of CData Connect Cloud.";

  return (
    <FormGroup className="mb-3">
      <h5 className="mb-3">{title}</h5>
      <div className="mb-2">{nextBillDateOrExpirationMessage}</div>
      {subscriptionInfo?.billingStatus === BillingStatus.Trial && (
        <div className="subtitle d-block">{billingMessage}</div>
      )}
    </FormGroup>
  );
};
