export function setImpersonatingUserId(userId: string) {
  window.sessionStorage.setItem("userIdToImpersonate", userId);
}

export function setIsSupportImpersonationActive(isActive: boolean) {
  window.sessionStorage.setItem(
    "isSupportImpersonationActive",
    isActive.toString(),
  );
}

export function getIsSupportImpersonationActive() {
  return (
    (window.sessionStorage.getItem("isSupportImpersonationActive") ??
      "false") === "true"
  );
}

export function clearImpersonation() {
  window.sessionStorage.removeItem("userIdToImpersonate");
}

export function getImpersonatingUserId() {
  return window.sessionStorage.getItem("userIdToImpersonate") ?? undefined;
}

export function isImpersonating() {
  return window.sessionStorage.getItem("userIdToImpersonate") != null;
}

export function setAdminUserEmail(email: string) {
  window.sessionStorage.setItem("adminAccountEmail", email);
}

export function getAdminUserEmail() {
  return window.sessionStorage.getItem("adminAccountEmail") ?? undefined;
}
