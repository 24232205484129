import { AnyAction } from "redux";
import { IConnection } from "../../models";
import * as types from "../constants";

const initialState: IConnection | null = null;

export default function reducer(
  state = initialState,
  action: AnyAction,
): IConnection | null {
  switch (action.type) {
    case types.ADD_CONNECTION:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
