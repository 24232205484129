import {
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import { ButtonType, CDataButton } from "../components/buttons/CDataButton";
import { CDataModalV2 } from "../components/modal/CDataModalV2";
import { logoutUser } from "../routes/AuthorizeContext";
import { ToastrError } from "../services/toastrService";
import { HttpError } from "./cdataFetch";

interface ICDataQueryClientProviderProps {
  children: ReactNode;
}

export function CDataQueryClientProvider(
  props: ICDataQueryClientProviderProps,
) {
  const { children } = props;

  const [show401Modal, setShow401Modal] = useState(false);

  function onQueryError(
    error: Error,
    queryOrMutation:
      | Query<unknown, unknown, unknown>
      | Mutation<unknown, unknown, unknown, unknown>,
  ) {
    // On 401 errors, we show the user a modal asking them to go back to the log in page.
    if (error instanceof HttpError && error.statusCode === 401) {
      const isOemUser = window.location.pathname.startsWith("/oem/user/");
      // OEM users don't have an actual login, do not send them to the login page.
      if (!isOemUser) {
        setShow401Modal(true);
      }
      return;
    }

    const errorMessage: string | undefined =
      queryOrMutation?.meta?.errorMessage;

    if (errorMessage == null || errorMessage.length === 0) {
      return;
    }

    if (error instanceof HttpError && error.statusCode === 404) {
      ToastrError(errorMessage, "404: Endpoint not found");
    } else {
      ToastrError(errorMessage, error.message);
    }
  }

  // Setup for Tanstack useQuery
  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onError: (error, _variables, _context, mutation) => {
        onQueryError(error, mutation);
      },
    }),
    queryCache: new QueryCache({
      onError: (error, query) => {
        onQueryError(error, query);
      },
    }),
  });

  return (
    <>
      <CDataModalV2
        title="Session Timeout"
        close={() => setShow401Modal(false)}
        displayed={show401Modal}
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={() => logoutUser()}
          >
            Return to Login
          </CDataButton>
        }
        displayToggleCloseButton={false}
      >
        <text>Your session has timed out, please log in again</text>
      </CDataModalV2>

      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </>
  );
}
