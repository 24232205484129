import React, { FocusEvent, FocusEventHandler } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { useField } from "formik";
import classNames from "classnames";

type TextInputProps = {
  className?: string;
  label: string;
  name: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  ["data-testid"]?: string;
  ["aria-label"]?: string;
  onChange?: FormControlProps["onChange"];
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

const TextInput = (props: TextInputProps) => {
  const [field, meta] = useField(props.name);

  return (
    <Form.Group
      className={classNames("component-TextInput", "mb-3", props.className)}
    >
      {props.label && (
        <h5 className={props.isRequired ? "required" : ""}>{props.label}</h5>
      )}
      <Form.Control
        name={props.name}
        key={props.name}
        type="text"
        value={field.value}
        isInvalid={Boolean(meta.touched && meta.error)}
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          field.onBlur(event);
          if (props.onBlur != null) {
            props.onBlur(event);
          }
        }}
        onChange={(event) => {
          field.onChange(event);
          if (props.onChange != null) {
            props.onChange(event);
          }
        }}
        disabled={props.isDisabled}
        data-testid={props["data-testid"]}
        aria-label={props["aria-label"]}
      />
      {!!meta.touched && (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default TextInput;
