import React from "react";
import { useField } from "formik";
import { CDataTextField, CDataTextFieldProps } from "./CDataTextField";

interface CDataFormFieldProps extends Omit<CDataTextFieldProps, "name"> {
  name: string;
  errorMessage?: string; // Only necessary if you need to manually control the displayed errorMessage
  ["data-testid"]?: string;
}

export function CDataFormTextField(props: CDataFormFieldProps) {
  const { errorMessage, ...textFieldProps } = props;

  const [field, meta] = useField(props.name);
  let helperText = props.helperText;
  if (meta.error && meta.touched) {
    helperText = meta.error;
  } else if (errorMessage) {
    helperText = errorMessage;
  }

  return (
    <CDataTextField
      {...textFieldProps}
      name={props.name}
      type={props.type ? props.type : "text"}
      value={field.value}
      isInvalid={Boolean(meta.touched && meta.error) || props.isInvalid}
      helperText={helperText}
      onBlur={(event: any) => {
        field.onBlur(event);
        if (props.onBlur != null) {
          props.onBlur(event);
        }
      }}
      onChange={(event: any) => {
        field.onChange(event);
        if (props.onChange != null) {
          props.onChange(event);
        }
      }}
    />
  );
}
