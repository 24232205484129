import React, { useState } from "react";
import classnames from "classnames";
import TanstackPaginationButtons from "./TanstackPaginationButtons";

import {
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TanstackCheckboxes } from "./TanstackCheckboxes";
import { SortIcon } from "./components/SortIcon";
import { SearchInput } from "./components/SearchInput";

/* A tanstack table that displays a list of non-editable rows, with optional support for filtering, checkboxes, and delete */

export type ColumnDefWithClassName<T> = ColumnDef<T> & {
  className: string;
};

export interface IListTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  className?: string;
  defaultSort?: SortingState;
  emptyTableMessage: string;
  enableFiltering: boolean;
  enablePagination: boolean;
  enableCheckboxes: boolean;
  searchPlaceholder?: string;
  /**
   * If set, this is used to filter the rows of the table.
   * This allows the parent component to render it's own search field outside the table.
   */
  globalSearchValue?: string;
  onRowClick?: (row: T) => void;
  selection?: RowSelectionState; // Only needed if checkboxes are enabled
  setSelection?: OnChangeFn<RowSelectionState>; // Only needed if checkboxes are enabled
}

function ListTable<T>(props: IListTableProps<T>) {
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [sorting, setSorting] = useState<SortingState>(
    props.defaultSort
      ? props.defaultSort
      : [
          {
            id: "name",
            desc: true,
          },
        ],
  );

  const data = props.data;
  const columns = props.columns;

  if (props.enableCheckboxes) {
    if (props.selection == null || props.setSelection == null) {
      throw new Error(
        "When enableCheckboxes is true, the props selection and setSelection are required.",
      );
    }

    columns.unshift(TanstackCheckboxes<T>());
  }

  const table = useReactTable<T>({
    data,
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    columns,
    state: {
      rowSelection: props.selection,
      globalFilter: props.globalSearchValue ?? globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: any) => row.id,
    onRowSelectionChange: props.setSelection,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: props.enableCheckboxes,
    getPaginationRowModel: props.enablePagination
      ? getPaginationRowModel()
      : undefined,
    debugTable: true,
  });

  function handleRowClick(
    row: T,
    event: React.MouseEvent<HTMLTableRowElement>,
  ) {
    if (
      event.target instanceof HTMLButtonElement ||
      event.target instanceof HTMLInputElement
    ) {
      // Prevents onRowClick from being called if the user is clicking a button in the row
      return;
    }
    props.onRowClick?.(row);
  }

  return (
    <div className="info-list-table">
      {props.enableFiltering ? (
        <SearchInput
          value={globalFilter}
          setValue={setGlobalFilter}
          searchPlaceholder={props.searchPlaceholder}
          className="mb-3"
        />
      ) : null}
      <table className={classnames(["listTable-table", props.className])}>
        <thead className="listTable-thead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={classnames([
                "listTable-thead-tr",
                {
                  "checkbox-sizing": props.enableCheckboxes,
                },
              ])}
            >
              {headerGroup.headers.map((header) => (
                // The columnDef needs to be any because we do not know what type is being used for the column def inside of ListTable
                <th
                  key={header.id}
                  className={classnames(
                    "listTable-th",
                    header.column.columnDef.meta?.className,
                  )}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        header.column.getCanSort()
                          ? "d-flex align-items-center cursor-pointer select-none"
                          : "d-flex align-items-center"
                      }
                      onClick={() => {
                        if (header.column.getCanSort()) {
                          const isCurrentlySorted = sorting.find(
                            (s) => s.id === header.column.columnDef.id,
                          );
                          setSorting([
                            {
                              id: header.column.columnDef.id as string,
                              desc: isCurrentlySorted
                                ? !isCurrentlySorted.desc
                                : false,
                            },
                          ]);
                        }
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() ? (
                        <SortIcon
                          isSorted={header.column.getIsSorted()}
                          nextSortOrder={header.column.getNextSortingOrder()}
                        />
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="listTable-tbody">
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr
                data-testid={`row-${row.id}`}
                key={row.id}
                className={classnames([
                  "listTable-tbody-tr",
                  {
                    "checkbox-sizing": props.enableCheckboxes,
                    "cursor-pointer": props.onRowClick,
                  },
                ])}
                onClick={(event) => handleRowClick(row.original, event)}
              >
                {row.getVisibleCells().map((cell) => (
                  // The columnDef needs to be any because we do not know what type is being used for the column def inside of ListTable
                  <td
                    key={cell.id}
                    className={classnames([
                      "listTable-td",
                      cell.column.columnDef.meta?.className,
                    ])}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                data-testid="empty-table-message"
                className="empty-table-message"
              >
                {props.emptyTableMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {props.enablePagination ? (
        <TanstackPaginationButtons
          table={table}
          paginationOptions={[5, 10, 20, 50, 100]}
        />
      ) : null}
    </div>
  );
}

export default ListTable;
