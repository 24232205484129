import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { IAccount } from "../../../../models";
import { YupOptionalSchema } from "../../../../utility/types/yupHelperTypes";
import {
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
} from "reactstrap";
import { countryList } from "../../../../utility/Countries";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { IUpdateOrganizationRequest } from "../../../../models/Accounts/IUpdateOrganizationRequest";
import { ToastrSuccess } from "../../../../services/toastrService";
import { addAccountInfo } from "../../../../redux/actions";
import { DisabledInputWithCopyButton } from "../../../../components/form/DisabledInputWithCopyButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

const validationSchema = Yup.object<YupOptionalSchema<IAccount>>({
  organization: Yup.string()
    .min(
      1,
      "Organization name must be 2-50 ASCII letters, numbers, hyphens, and underscores; and must begin and end with a letter/number.",
    )
    .max(
      100,
      "Organization name must be 2-50 ASCII letters, numbers, hyphens, and underscores; and must begin and end with a letter/number.",
    )
    .matches(
      /^[a-zA-Z0-9][ a-zA-Z0-9_-]{0,48}[a-zA-Z0-9]$/,
      "Organization name must be 2-50 ASCII letters, numbers, hyphens, underscores, and spaces; and must begin and end with a letter/number.",
    )
    .required("This is a required field"),
});

/**
 * The OEM account details card is almost the same as the regular account one except:
 * - We allow them to change their organization name.
 * - They get an input field that lets them copy and paste their account ID.
 */
export function OEMAccountDetailsForm() {
  const account = useAppSelector((s) => s.accountInfo);
  const dispatch = useAppDispatch();

  const api = useAPI();

  async function onSubmit(values: IAccount, actions: FormikHelpers<IAccount>) {
    const payload: IUpdateOrganizationRequest = {
      organization: values.organization!,
    };
    const { status } = await api.callAPI(
      RequestType.Put,
      "/account/organization",
      "Failed to update the organization for the Account",
      payload,
    );

    if (status === 200) {
      ToastrSuccess(
        "Account Details Successfully Saved!",
        "You have successfully updated your account details.",
      );

      dispatch(addAccountInfo(account));

      actions.resetForm({
        values,
      });
    }
  }

  return (
    <Formik
      initialValues={account}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(values, actions) => onSubmit(values, actions)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        isValid,
        dirty,
        isSubmitting,
      }) => {
        return (
          <Form className="oemAccountDetailsForm">
            <Card>
              <CardBody>
                <div className="detailsHeader">
                  <h4 className="mb-4">Details</h4>
                  <div>
                    <CDataButton
                      buttonType={ButtonType.Primary}
                      onClick={() => handleSubmit()}
                      className="card-actions float-end"
                      disabled={!isValid || !dirty || isSubmitting}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        className="small-icon align-middle no-pointer-event"
                      />
                      Save Changes
                    </CDataButton>
                  </div>
                </div>
                <div className="detailsRow">
                  <Col>
                    <FormGroup>
                      <label
                        htmlFor="organization-input"
                        className="fw-bold mb-2"
                      >
                        Organization Name
                      </label>
                      <Input
                        id="organization-input"
                        name="organization"
                        className="organization-name"
                        value={values.organization}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={!!errors.organization}
                      />
                      <FormFeedback>{errors.organization}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <label className="fw-bold mb-2">Account Id</label>
                    <DisabledInputWithCopyButton text={account.id!} />
                  </Col>
                  <Col>
                    <label className="fw-bold mb-2">Country</label>
                    <div className="countryName">
                      {account.country
                        ? countryList.find(
                            (t) => t.threeLetterCode === account.country,
                          )?.name ?? ""
                        : ""}
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}
