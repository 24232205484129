import { Switch, SwitchProps } from "@mui/material";
import classNames from "classnames";

export interface CDataToggleButtonProps extends Omit<SwitchProps, "ref"> {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export function CDataToggleButton(props: CDataToggleButtonProps) {
  return (
    <Switch
      {...props}
      className={classNames("cdata-toggle-button", props.className)}
      inputProps={props.inputProps}
      disableRipple
    />
  );
}
