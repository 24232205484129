import React from "react";
import { connect } from "react-redux";

const Wrapper = ({ layout, children }: any) => (
  <div className={"wrapper " + (layout.isBoxed ? "wrapper-boxed" : "")}>
    {children}
  </div>
);

export default connect((store) => ({
  // @ts-ignore prexisting issue, needs refactor
  layout: store.layout,
}))(Wrapper);
