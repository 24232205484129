import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";

export function TanstackCheckboxes<T>(): ColumnDef<T> {
  return {
    id: "select",
    size: 0,
    header: ({ table }) => (
      <span className="px-1">
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </span>
    ),
    cell: ({ row }) => (
      <span className="px-1">
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </span>
    ),
  };
}
