import { UserRole } from "../models";
import { SettingTabs } from "../pages/settings/Settings";

export function getSettingsPageSubscriptionTab() {
  return window.VITE_CONFIG?.VITE_SUBSCRIPTION_PROVIDER === "Stripe"
    ? SettingTabs.Billing
    : SettingTabs.License;
}

export function isBillingTabVisible() {
  switch (window.VITE_CONFIG.VITE_SUBSCRIPTION_PROVIDER) {
    case "Stripe":
      return true;
    case "Redistribution":
      return false;
    default:
      return false;
  }
}

export function isLicenseTabVisible() {
  switch (window.VITE_CONFIG.VITE_SUBSCRIPTION_PROVIDER) {
    case "Stripe":
      return false;
    case "Redistribution":
      return false;
    default:
      return true;
  }
}

export function isManageSubscriptionVisible(
  userRole: UserRole | null | undefined,
) {
  // Only admins can manage billing.
  if (userRole !== UserRole.Admin && userRole !== UserRole.OEMAdmin) {
    return false;
  }
  return window.VITE_CONFIG.VITE_SUBSCRIPTION_PROVIDER !== "Redistribution";
}

export function ManageSubscriptionText() {
  return window.VITE_CONFIG.VITE_SUBSCRIPTION_PROVIDER === "Stripe"
    ? "Manage Subscription"
    : "Manage License";
}
