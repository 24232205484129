import { Badge } from "reactstrap";
import { BillingStatus } from "../models";
import React from "react";

interface IStatusProps {
  className: string;
  status: BillingStatus | null | undefined;
}

export function Status({ className, status }: IStatusProps) {
  let title: string | undefined = undefined;
  let color: string | undefined = undefined;
  switch (status) {
    case BillingStatus.Trial:
      title = "Trial";
      color = "quaternary";
      break;
    case BillingStatus.TrialEnding:
      title = "Trial Ending Soon";
      color = "warning";
      break;
    case BillingStatus.Active:
      title = "Active";
      color = "success";
      break;
    case BillingStatus.Unpaid:
      title = "Past Due";
      color = "danger";
      break;
    case BillingStatus.Canceled:
      title = "Canceled";
      color = "secondary";
      break;
    case BillingStatus.ActiveEnding:
      title = "Cancellation Pending";
      color = "danger";
      break;
    case BillingStatus.Paused:
      title = "Paused";
      color = "secondary";
      break;
  }

  return (
    <Badge className={className} color={color}>
      {title}
    </Badge>
  );
}
