import {
  ButtonBase,
  ClickAwayListener,
  createTheme,
  InputBase,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";

interface IDisabledInputWithCopyButton {
  id?: string;
  text: string;
}

// We would normally do this through CSS. However, the tooltip popovers have to be able to overlap modals,
// which means that we have to set "disablePortal" to false in the tooltip object. As a result, the themes
// in this component's CSS file are not seen by the component, and so we have to create a theme here manually.
const tooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#fff",
          color: "#3f4b57",
          fontWeight: 400,
          fontSize: "12px",
          border: "1px solid #dfe0e1",
          borderRadius: "0.5rem",
          padding: "15px",
        },

        arrow: {
          color: "#fff",
          "&&:before": {
            border: "1px solid #dfe0e1",
          },
        },
      },
    },
  },
});

export const DisabledInputWithCopyButton = (
  props: IDisabledInputWithCopyButton,
) => {
  const { id, text } = props;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  return (
    <span className="disabled-input-with-copy-button">
      <InputBase
        id={id ?? ""}
        className="disabled-text-field"
        disabled
        value={text}
        endAdornment={
          <>
            <ThemeProvider theme={tooltipTheme}>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  title="Copied Successfully!"
                  placement="top"
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  PopperProps={{
                    disablePortal: false,
                  }}
                >
                  <ButtonBase
                    onClick={handleTooltipOpen}
                    onBlur={handleTooltipClose}
                  >
                    <i className="fa-solid fa-copy align-middle no-pointer-event" />
                  </ButtonBase>
                </Tooltip>
              </ClickAwayListener>
            </ThemeProvider>
          </>
        }
      />
    </span>
  );
};
