import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export function useAuth0Hook() {
  const version = useAuth0();

  const auth0Fields = {
    Email: version.user?.email,
    Name: version.user?.name,
    Logout: version.logout,
    IsLoading: version.isLoading,
    GetAccessTokenSilently: version.getAccessTokenSilently,
    IsAuthenticated: version.isAuthenticated,
    User: version.user,
    Email_verified: version.user?.email_verified,
    WithAuthenticationRequired: withAuthenticationRequired,
    ProviderVersion: "Auth0", // This field is only used for testing.
    GetIdTokenClaims: version.getIdTokenClaims,
  };

  return auth0Fields;
}
