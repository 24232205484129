import { FeatureId } from "../models";

export function isFeatureAllowed(
  availableFeatureIds: FeatureId[],
  featureId: FeatureId,
): boolean {
  if (availableFeatureIds.length > 0 && featureId) {
    return availableFeatureIds.includes(featureId);
  }
  return false;
}
