import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { useEffect, useRef } from "react";
import { UserRole } from "../../models";
import { useAppSelector } from "../../redux/hooks";

/**
 * Controls the visibility of the happy fox chat button that is loaded through Google Tag Manager
 * The chat is not shown for OEM users and spreadsheet only users.
 */
export function ShowHappyFoxChat() {
  const user = useAppSelector((state) => state.user);
  const subscription = useAppSelector((state) => state.subscription);
  const isSpreadsheetUser = useIsConnectForSpreadsheets();

  const timer = useRef<number | null>(null);

  function clearTimer() {
    if (timer.current != null) {
      clearInterval(timer.current);
      timer.current = null;
    }
  }

  useEffect(() => {
    // Wait until we've loaded the user information.
    if (user == null || subscription == null) return;

    // This is very hacky but we usually load google tag manager before the user information is loaded
    // so we cannot tell google tag manager to not include the chat widget.
    // As a result, the chat is often loaded right away and we just have to hide it until we know what type of user they are.
    // The chat loads in the background, so we can't guarantee that it's there when this code runs, hence the interval.
    timer.current = window.setInterval(() => {
      const happyFoxChatIFrame = document.getElementById("hfc-frame");
      if (happyFoxChatIFrame != null) {
        if (user?.role === UserRole.ServiceUser || isSpreadsheetUser) {
          happyFoxChatIFrame.classList.remove("displayed");
        } else {
          happyFoxChatIFrame.classList.add("displayed");
        }
        clearTimer();
      }
    }, 250);

    return () => {
      clearTimer();
    };
  }, [user, isSpreadsheetUser, subscription]);

  return <></>;
}
