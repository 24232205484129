import { configureStore } from "@reduxjs/toolkit";
import accountInfoReducer from "../reducers/accountInfoReducer";
import cacheConnectionReducer from "../reducers/cacheConnectionReducer";
import dataExplorerReducer from "../reducers/dataExplorerReducer";
import flyoutReducer from "../reducers/flyoutReducer";
import layoutReducer from "../reducers/layoutReducer";
import sidebarReducers from "../reducers/sidebarReducers";
import subscriptionReducer from "../reducers/subscriptionReducer";
import usageReducer from "../reducers/usageReducer";
import userReducer from "../reducers/userReducer";
import driversListReducers from "../reducers/driversListReducers";

export const allReducers = {
  accountInfo: accountInfoReducer,
  cacheConnection: cacheConnectionReducer,
  dataExplorerTabs: dataExplorerReducer,
  driversList: driversListReducers,
  flyout: flyoutReducer,
  layout: layoutReducer,
  sidebar: sidebarReducers,
  subscription: subscriptionReducer,
  usage: usageReducer,
  user: userReducer,
};

export const store = configureStore({
  reducer: allReducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
