import { UTCDate } from "@date-fns/utc";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";

/** Converts a passed in date string, Date object, or number into a UTC date time string. The default formatting is "yyyy-MM-dd HH:mm:ss", and it can be overridden by passing in a different string. */
export function formatUTCDateTime(
  date: string | Date | number,
  dateFormat?: string,
) {
  return format(new UTCDate(date), dateFormat ?? "yyyy-MM-dd HH:mm:ss", {
    locale: enUS,
  });
}
