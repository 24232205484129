import React from "react";
import { Container, Row, Spinner } from "reactstrap";

interface ILoaderProps {
  customHeightClass?: string;
}

const Loader = ({ customHeightClass }: ILoaderProps) => {
  const heightClass = customHeightClass ? customHeightClass : "vh-50";

  return (
    <Container fluid className={`d-flex ${heightClass}`}>
      <Row className="justify-content-center align-self-center w-100 text-center">
        <Spinner color="info" />
      </Row>
    </Container>
  );
};

export default Loader;
