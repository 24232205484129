import { createTheme } from "@mui/material";

// https://mui.com/material-ui/customization/theme-components/
export const theme = createTheme({
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "top",
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "#54585E",
        },
        arrow: {
          "&:before": {
            backgroundColor: "#54585E",
          },
        },
      },
    },
  },
});
