import { IAccount } from "../../models";
import { AnyAction } from "redux";
import * as types from "../constants";

const initialState: IAccount = null!;

export default function reducer(
  state = initialState,
  action: AnyAction,
): IAccount {
  switch (action.type) {
    case types.ADD_ACCOUNT_INFO:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
