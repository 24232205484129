import * as types from "../constants";

interface ISidebarState {
  isOpen: boolean;
  isSticky: boolean;
  keepExpanded: boolean;
}

const initialState: ISidebarState = {
  isOpen: true,
  isSticky: false,
  keepExpanded: true,
};

export default function reducer(
  state: ISidebarState = initialState,
  actions: any,
): ISidebarState {
  switch (actions.type) {
    case types.SIDEBAR_VISIBILITY_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
        keepExpanded: !state.keepExpanded,
      };
    case types.SIDEBAR_VISIBILITY_SHOW:
      return {
        ...state,
        isOpen: true,
      };
    case types.SIDEBAR_VISIBILITY_HIDE:
      return {
        ...state,
        isOpen: false,
      };

    case types.SIDEBAR_STICKY_TOGGLE:
      return {
        ...state,
        isSticky: !state.isSticky,
      };
    case types.SIDEBAR_STICKY_ENABLE:
      return {
        ...state,
        isSticky: true,
      };
    case types.LAYOUT_BOXED_ENABLE:
    case types.LAYOUT_BOXED_TOGGLE:
    case types.SIDEBAR_STICKY_DISABLE:
      return {
        ...state,
        isSticky: false,
      };

    default:
      return state;
  }
}
