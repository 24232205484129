import { RequestType } from "../../../components/withAPI";

export async function getOEMSubAccounts(
  callAPI: Function,
  disableImpersonation?: boolean,
) {
  const { payload, status } = await callAPI(
    RequestType.Get,
    "/account/oem/subAccounts",
    "Failed to retrieve OEM sub-accounts.",
    null,
    null,
    null,
    disableImpersonation,
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function getSubAccountUserId(
  callAPI: Function,
  accountId: string,
  disableImpersonation?: boolean,
) {
  const { payload, status } = await callAPI(
    RequestType.Get,
    `/users/oem/${accountId}`,
    "Failed to retrieve account information.",
    null,
    null,
    null,
    disableImpersonation,
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}
