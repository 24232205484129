import { LogoutOptions } from "@auth0/auth0-react";
import { clearImpersonation } from "../services/userImpersonation";
import { useAuth0Hook } from "./useAuth0Hook";

export function useAuthentication() {
  const version = useAuth0Hook();

  const authFields = {
    Email: version.Email,
    Name: version.Name,
    Logout: (options?: LogoutOptions | undefined) => {
      // Clear impersonation so the user isn't immediately impersonating when they log back in.
      clearImpersonation();
      return version.Logout(options);
    },
    IsLoading: version.IsLoading,
    GetAccessTokenSilently: version.GetAccessTokenSilently,
    IsAuthenticated: version.IsAuthenticated ?? false,
    User: version.User,
    Email_verified: version.Email_verified,
    WithAuthenticationRequired: version.WithAuthenticationRequired,
    ProviderVersion: version.ProviderVersion,
    GetIdTokenClaims: version.GetIdTokenClaims,
  };

  return authFields;
}
