import { ISubscriptionInfo } from "../../models";
import { AnyAction } from "redux";
import * as types from "../constants";

const initialState: ISubscriptionInfo = null!;

export default function reducer(
  state = initialState,
  action: AnyAction,
): ISubscriptionInfo {
  switch (action.type) {
    case types.ADD_SUBSCRIPTION:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
