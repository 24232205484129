import { store } from "../redux/store";

export enum CountryEmailDomain {
  USA = "cdata.com",
  JPN = "cdata.co.jp",
}

function getEmailDomain(countryCode?: string): string {
  const accountInfoCountry = store.getState().accountInfo?.country;

  if (countryCode) {
    return (
      CountryEmailDomain[countryCode as keyof typeof CountryEmailDomain] ||
      CountryEmailDomain.USA
    );
  }
  if (accountInfoCountry) {
    return (
      CountryEmailDomain[
        accountInfoCountry as keyof typeof CountryEmailDomain
      ] || CountryEmailDomain.USA
    );
  }
  return CountryEmailDomain.USA;
}

export function getSalesEmailAddress(countryCode?: string): string {
  const emailDomain = getEmailDomain(countryCode);
  return `sales@${emailDomain}`;
}

export function getSupportEmailAddress(countryCode?: string): string {
  const emailDomain = getEmailDomain(countryCode);
  return `support@${emailDomain}`;
}
