import { BillingStatus, IBillingUsage, UserRole } from "../../models";
import { ReactNode, useContext } from "react";
import { browserName } from "react-device-detect";

import {
  DashboardNotificationManagerConstants as constants,
  ACTIVATE_PLAN,
  BILLING_TAB_DESTINATION,
  LEARN_MORE,
  MANAGE_BILLING,
  SUPPORTED_BROWSERS,
  UPGRADE_PLAN,
  VIEW_WORKSPACES,
  WORKSPACE_TAB_DESTINATION,
} from "./DashboardNotificationManagerConstants";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import { isMacSafariWebView } from "./NotificationBarUtil";
import { differenceInCalendarDays, parseISO } from "date-fns";
import {
  IconOverrideOptions,
  INotificationBar,
  NotificationBar,
} from "./NotificationBar";
import { ModalContext } from "../../routes/ModalContext";
import { useAppSelector } from "../../redux/hooks";
import { useIsSpreadsheetUser } from "../../hooks/useIsSpreadsheetUser";

export const DashboardNotificationManager = () => {
  const currentUser = useAppSelector((state) => state.user);
  const usage = useAppSelector((state) => state.usage);
  const subscription = useAppSelector((state) => state.subscription);
  const cacheConnection = useAppSelector((state) => state.cacheConnection);
  const modalContext = useContext(ModalContext);
  const isAdmin = currentUser.role === UserRole.Admin;
  const isSpreadsheetsUser = useIsSpreadsheetUser();

  // Cache connection edit page
  const cacheConnectionNotice = createCachingConnectionNotification(
    cacheConnection?.id,
  );
  if (cacheConnectionNotice != null) {
    return <NotificationBar {...cacheConnectionNotice} />;
  }

  // Billing status issues
  const billingIssue = createBillingIssueNotification(
    isAdmin,
    subscription.billingStatus,
  );
  if (billingIssue != null) {
    return <NotificationBar {...billingIssue} />;
  }

  // Overage notifications
  const overage = createOverageNotification(isAdmin, usage);
  if (overage != null) {
    return <NotificationBar {...overage} />;
  }

  // Trial row notifications
  const trialRowsExceeded = createTrialRowNotification(
    isAdmin,
    usage.rowsOverLimit!,
    subscription.billingStatus,
  );
  if (trialRowsExceeded != null) {
    return <NotificationBar {...trialRowsExceeded} />;
  }

  // Trial time notifications
  const trialTimeRemaining = createTrialTimeNotification(
    isAdmin,
    isSpreadsheetsUser,
    subscription.billingStatus,
    subscription.nextBillTime!,
  );
  if (trialTimeRemaining != null) {
    return <NotificationBar {...trialTimeRemaining} />;
  }

  // Unsupported browser alert
  const unsupportedBrowser = createBrowserNotification(
    modalContext.showUnsupportedBrowser,
  );
  if (unsupportedBrowser != null) {
    return <NotificationBar {...unsupportedBrowser} />;
  }

  // OData is deprecated
  const deprecatedOData = createODataNotification();
  if (deprecatedOData != null) {
    return <NotificationBar {...deprecatedOData} />;
  }

  return;
};

export function createCachingConnectionNotification(
  id: string | null | undefined,
): INotificationBar | null {
  if (window.location.pathname === "/caching/cacheConnection" && id) {
    return {
      barColor: "notification-bar-orange",
      message:
        "Modifying your PostgresSQL connection will result in the clearing of cached data and trigger a restart for jobs that depend on this information.",
      iconOverride: IconOverrideOptions.Triangle,
    };
  }
  return null;
}

// Alerts user whose account is unpaid, canceled, or paused that they need to fix things
export function createBillingIssueNotification(
  isAdmin: boolean,
  billingStatus: BillingStatus,
): INotificationBar | null {
  const notificationData: INotificationBar = {
    message: "",
    barColor: "notification-bar-red",
    linkText: isAdmin ? MANAGE_BILLING : undefined,
    navigateDestination: isAdmin ? BILLING_TAB_DESTINATION : undefined,
  };

  switch (billingStatus) {
    case BillingStatus.Unpaid:
      notificationData.message = isAdmin
        ? constants.billingIssueMessages.unpaidAdmin
        : constants.billingIssueMessages.unpaidQuery;
      break;
    case BillingStatus.Canceled:
      notificationData.message = isAdmin
        ? constants.billingIssueMessages.canceledAdmin
        : constants.billingIssueMessages.canceledQuery;
      break;
    case BillingStatus.Paused:
      notificationData.message = isAdmin
        ? constants.billingIssueMessages.pausedAdmin
        : constants.billingIssueMessages.pausedQuery;
      notificationData.linkText = isAdmin ? ACTIVATE_PLAN : undefined;
      break;
    default:
      return null;
  }
  return notificationData;
}

// Alerts users about overages for user seats, connections, and both
export function createOverageNotification(
  isAdmin: boolean,
  usage: IBillingUsage,
): INotificationBar | null {
  let message = "";

  if (usage?.connectionsOverLimit)
    message = isAdmin
      ? constants.overageMessagesAdmin.connections
      : constants.overageMessagesQuery.connections;
  else if (usage?.dataSourcesOverLimit)
    message = isAdmin
      ? constants.overageMessagesAdmin.dataSources
      : constants.overageMessagesQuery.dataSources;
  else if (usage.connectionsPerDataSourceOverLimit)
    message = isAdmin
      ? constants.overageMessagesAdmin.connectionsPerDataSource
      : constants.overageMessagesQuery.connectionsPerDataSource;
  else if (usage.usersOverLimit)
    message = isAdmin
      ? constants.overageMessagesAdmin.userSeats
      : constants.overageMessagesQuery.userSeats;
  else return null;

  return {
    message: message,
    barColor: "notification-bar-red",
    linkText: isAdmin ? UPGRADE_PLAN : undefined,
    navigateDestination: isAdmin ? BILLING_TAB_DESTINATION : undefined,
  };
}

// Informs trial user who has exceeded their row limit that they need to upgrade
export function createTrialRowNotification(
  isAdmin: boolean,
  rowsOverLimit: boolean,
  billingStatus: BillingStatus,
): INotificationBar | null {
  if (
    rowsOverLimit &&
    [
      BillingStatus.NewAccount,
      BillingStatus.Trial,
      BillingStatus.TrialEnding,
    ].includes(billingStatus)
  ) {
    return {
      message: isAdmin
        ? constants.trialRowOverageMessages.admin
        : constants.trialRowOverageMessages.query,
      barColor: "notification-bar-red",
      linkText: isAdmin ? UPGRADE_PLAN : undefined,
      navigateDestination: isAdmin ? BILLING_TAB_DESTINATION : undefined,
    };
  }
  return null;
}

// Informs trial user how many days they have left in their trial or, alternatively, that their trial has expired
export function createTrialTimeNotification(
  isAdmin: boolean,
  isSpreadsheetsUser: boolean,
  billingStatus: BillingStatus,
  nextBillTime: string,
): INotificationBar | null {
  if (
    ![
      BillingStatus.NewAccount,
      BillingStatus.Trial,
      BillingStatus.TrialEnding,
    ].includes(billingStatus) ||
    window.location.pathname.toLocaleLowerCase() === "/odata" ||
    window.location.pathname.toLocaleLowerCase() === "/odata/editTable"
  ) {
    return null;
  }

  const days: number | null = nextBillTime
    ? differenceInCalendarDays(parseISO(nextBillTime), new Date())
    : null;
  const expiry =
    days && days >= 0 ? `is expiring in ${days} days!` : "has expired.";
  let message: ReactNode;

  if (isSpreadsheetsUser) {
    message = (
      <>
        Your trial is scheduled to expire in {days} days and will be downgraded
        to the &quot;Free&quot; plan. To avoid disruptions in service, upgrade
        your plan today.
      </>
    );
  } else if (isAdmin) {
    message = (
      <>
        Your CData Connect Cloud trial {expiry} To avoid disruptions in service,
        please contact&nbsp;
        <a href={`mailto:${getSalesEmailAddress()}`}>
          {getSalesEmailAddress()}
        </a>
        &nbsp;or click Upgrade Plan.
      </>
    );
  } else {
    message = (
      <>
        Your CData Connect Cloud trial {expiry} To avoid disruptions in service,
        please contact your system administrator to upgrade your plan today.
      </>
    );
  }

  return {
    message: message,
    barColor: "notification-bar-blue",
    linkText: isAdmin ? UPGRADE_PLAN : undefined,
    navigateDestination: isAdmin ? BILLING_TAB_DESTINATION : undefined,
  };
}

// Informs user with an unsupported browser that they should switch to a supported one
export function createBrowserNotification(
  showBrowserModal: Function,
): INotificationBar | null {
  if (
    !SUPPORTED_BROWSERS.includes(browserName) &&
    !isMacSafariWebView(navigator.userAgent)
  ) {
    return {
      message: constants.unsupportedBrowser.message,
      barColor: "notification-bar-blue",
      linkText: LEARN_MORE,
      linkClickOverride: showBrowserModal as () => void,
    };
  }
  return null;
}

// On the OData page, informs user about the deprecated OData and advises them to make a transition to Virtual Datasets
export function createODataNotification(): INotificationBar | null {
  if (
    window.location.pathname.toLocaleLowerCase() === "/odata" ||
    window.location.pathname.toLocaleLowerCase() === "/odata/editTable"
  ) {
    return {
      message: constants.deprecatedOData.message,
      barColor: "notification-bar-orange",
      linkText: VIEW_WORKSPACES,
      navigateDestination: WORKSPACE_TAB_DESTINATION,
    };
  }
  return null;
}
