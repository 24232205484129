import React from "react";
import { Button, ButtonProps } from "reactstrap";

type ITertiaryButtonProps = ButtonProps;

const TertiaryButton = (props: ITertiaryButtonProps) => {
  const { className, children, ...buttonProps } = props;
  return (
    <Button
      className={className ? className + " tertiary-button" : "tertiary-button"}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default TertiaryButton;
