import { lazy } from "react";
import asyncRetry from "async-retry";

/**
 * A wrapper around React lazy imports to support retrying when the bundle fails to load
 * due to a network issue.
 */
export const lazyRetry: typeof lazy = (load) => {
  return lazy(() => {
    return asyncRetry(load, {
      retries: 5,
      minTimeout: 100,
    });
  });
};
