import { IUser } from "../../models";
import { AnyAction } from "redux";
import * as types from "../constants";

const initialState: IUser = null!;

export default function reducer(
  state = initialState,
  action: AnyAction,
): IUser {
  switch (action.type) {
    case types.ADD_USER:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
