import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IToastrBodyProps {
  body: string | JSX.Element;
  title?: string;
  details?: string | JSX.Element;
}

function DetailsTab({ details }: { details: IToastrBodyProps["details"] }) {
  return (
    <div className="details-tab">
      <div className="details-content">{details}</div>
    </div>
  );
}

export const ToastrBody = ({
  body,
  title,
  details,
}: IToastrBodyProps): JSX.Element => {
  return (
    <>
      <div className="toast-container">
        {title ? <div className="toast-title">{title}</div> : null}
        <div className="toast-body">{body}</div>
      </div>
      {details ? <DetailsTab details={details} /> : <></>}
    </>
  );
};

export const ToastrCloseButton = ({ closeToast }: any) => (
  <span className="toast-close-icon-span">
    <i onClick={closeToast}>
      <FontAwesomeIcon icon={faTimes} className="toast-close-icon" />
    </i>
  </span>
);
